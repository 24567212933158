import AddressEditModal from "./AddressEditModal";
import React, {useEffect, useState, useContext} from "react";
import {useHistory} from 'react-router';
import currencyFormat from "./../../../packages/chamaeleon-react/commerce-react-applications/helper/currencyFormat";
import CartRepository from "../../Cart/CartRepository";
import MiniCart from '../../Cart/MiniCart';
import { Link } from "react-router-dom";
import HTMLReactParser from 'react-html-parser';
import { StoreContext } from "../../Store";
import { getGAHook } from "../../GoogleAnalytics/GAServiceHook";

function CheckoutSummary(props) {
  const { contextState,setValues } = useContext(StoreContext)
  const cart = contextState.cart;
  const translations = contextState.translations;
  const checkout = contextState.checkout
  const history = useHistory();
  const [showContent, setShowContent] = useState(false);
  const workflowStatus = contextState.workflowStatus
  const isFormSubmitted = contextState.isFormSubmitted
  const selectedLanguageName = contextState.selectedLanguageName;
  const pathname = history.location.pathname;
  const  {fireCheckout} = getGAHook();


  useEffect(() => {
    const gaData = JSON.parse(sessionStorage.getItem("ga-data"));
    fireCheckout(gaData.items,2);
  }, [])
  
  
  useEffect(() => {
    if (workflowStatus === null) {
      setShowContent(false);
    } else if (workflowStatus === CartRepository.cancelled) {
      history.push('/cart');
    } else if (workflowStatus === CartRepository.failed) {
      history.push('/');
    } else if (workflowStatus === CartRepository.inProgress && isFormSubmitted === true) {

      setShowContent(true);
    } else if(!isFormSubmitted){
      history.push('/checkout/shipment-infos');
    }

  }, [workflowStatus]);

  let notification = <React.Fragment />;
  if (history.location && history.location['state'] && history.location['state']['from'] && history.location['state']['from'] === 'cancel') {
    notification =
        <div className="notification">
          <div className="notification__icon__box">
                      <span
                          className={`notification__icon posticon_Posticon-Information`}
                          aria-hidden="true"></span>
          </div>
          <div className="notifications__container">
            <div className="notification__text">
              Bestellung abgebrochen. Bitte versuchen Sie es noch einmal!
            </div>
          </div>
        </div>
    ;
  }

  
  
  if (showContent === false) {
    return <div className="spinner"><div></div></div>;
  }

  

  return (
    <div className="headerspace">
        <div className="container content__container">
          <div className="row content__row">
            <div className="col-md-12 col-lg-8">
              <main role="main" className="main">
                <div className="react-kassa">
                  <div id="checkout_summary" className="cham-form">
                    {notification}
                    <h2>{translations['checkout.summaryHeader'] }</h2>
                    
                    <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            
                            <h3 className="heading heading--h3">{translations['checkout.billingAddressHeader'] }</h3>
                              <div className="container-fluid versandinformationen">
                                <div className="invoiceaddress__view">
                                  {checkout.address && (
                                    <div>
                                        {checkout.address.salutation
                                          ? checkout.address.salutation
                                          : ""}{" "}
                                        {checkout.address.title
                                          ? checkout.address.title
                                          : ""}
                                      <br />
                                      <b>
                                        {checkout.address.firstname}{" "}
                                        {checkout.address.lastname}
                                      </b>
                                      <br />
                                      {checkout.address.street}{" "}
                                      {checkout.address.housenumber}
                                      {checkout.address.staircase
                                        ? "/" + checkout.address.staircase
                                        : ""}
                                      {checkout.address.doornumber
                                        ? "/" + checkout.address.doornumber
                                        : ""}
                                      <br />
                                      {checkout.address.areacode}{" "}
                                      {checkout.address.city}
                                      <br />
                                      {checkout.address.country
                                        ? checkout.address.country
                                        : ""}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {Object.keys(checkout.address).length > 0 && (
                                <AddressEditModal
                                  customer= {checkout.customer}
                                  title={translations['account.overviewChangeBilling']}
                                  btntitle={translations['account.addressEditBtn'] || ''}
                                  addressParam="address"
                                  address={checkout.address}
            
                                />
                              )}
                            
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                                <h3 className="heading heading--h3">{translations['checkout.shippingAddressHeader'] }</h3>
                                <div className="container-fluid versandinformationen">
                                  <div className="invoiceaddress__view">
                                    {checkout.shippingAddress && (
                                      <div>
                                          {checkout.shippingAddress.salutation
                                            ? checkout.shippingAddress.salutation
                                            : ""}{" "}
                                          {checkout.shippingAddress.title
                                            ? checkout.shippingAddress.title
                                            : ""}
                                        <br />
                                        <b>
                                          {checkout.shippingAddress.firstname}{" "}
                                          {checkout.shippingAddress.lastname}
                                        </b>
                                        <br />
                                        {checkout.shippingAddress.street}{" "}
                                        {checkout.shippingAddress.housenumber}
                                        {checkout.shippingAddress.staircase
                                          ? "/" + checkout.shippingAddress.staircase
                                          : ""}
                                        {checkout.shippingAddress.doornumber
                                          ? "/" + checkout.shippingAddress.doornumber
                                          : ""}
                                        <br />
                                        {checkout.shippingAddress.areacode}{" "}
                                        {checkout.shippingAddress.city}
                                        <br />
                                        {cart.deliveries[0]?.location?.address?.country?.name
                                          ? cart.deliveries[0]?.location.address.country.name
                                          : checkout.shippingAddress.country}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {Object.keys(checkout.shippingAddress).length > 0 && (
                                  <AddressEditModal
                                    customer= {checkout.customer}
                                    title= {translations['account.overviewChangeShipping'] } 
                                    btntitle={translations['account.addressEditBtn'] || ''}
                                    addressParam="shippingAddress"
                                    address={checkout.shippingAddress}
                                  />
                                )}
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="kassa sidebar__top">
                    <h3>{translations['custom.summary.orderDetail'] }</h3>
                    <div className="bestellposition">
                      <div className="bestellposition__orders">
                        {cart &&
                        cart.lineItems.length &&
                        cart.lineItems.map((line, index) => (
                            <div className="bestellposition__order" key={index}>
                              <div className="bestellposition-cart__container summary">
                                <div className="bestellposition-cart__detail">
                                  <div className="bestellposition__order-productname">
                                    {line.quantity} x {line.label}
                                  </div>
                                </div>
                                <div className="bestellposition-cart__price">
                                  <div className="bestellposition-cart__product-price">
                                    {`€ ${currencyFormat(line.price.totalPrice)}`}
                                  </div>
                                </div>
                              </div>
                            </div>   
                        ))}
                      </div>
                    </div>

                    <div className="kassa__detailedprice">
                      <div className="kassa__detailedprice-row total flexspace">
                        <div className="left-aligned">{translations['account.orderItemShippingcosts'] }</div>
                        <div className="right-aligned shipping">
                          {cart && (
                              <span>
                                <span>€ {cart?.deliveries[0]?.shippingCosts?.totalPrice ? `${currencyFormat(cart.deliveries[0].shippingCosts.totalPrice)}`: '0,00'}</span>
                              </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="kassa__detailedprice">
                      <div className="kassa__detailedprice-row total flexspace">
                      <div className="left-aligned">{translations['account.orderItemTotal'] }</div>
                      <div className="right-aligned">
                          {cart && (
                            <span>
                              <span>€ {`${currencyFormat(
                                  cart.price.totalPrice
                              )}`}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="sidebar col-md-12 col-lg-4">
              <div className="sidebar__container">
                <MiniCart/>
                <aside className="sidebar__main">
                  <p>
                  {HTMLReactParser(translations['custom.payinfo'])}
                  </p>
                  <p>
                    {/* Link element has to be used here*/}
                    <Link
                      to={( pathname.includes("/en") || pathname.includes("/en/")) ? "/en/checkout/payment" : "/checkout/payment" /* + this.nextStep() */}
                      className={"linkbtn linkbtn__primary "}>
                      <span className="linkbtn__text">{translations['custom.paybutton'] }</span>
                      <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </Link>
                  </p>
                  {/* <p>
                    <Link to={"https://crypto.post.at/onchainshop/"} className={"linkbtn linkbtn__secondary"}>
                      <span className="linkbtn__text">Zum On-Chain Shop</span>
                      <span className="linkbtn__icon posticon_Posticon-Button-Pfeil-Weiter"></span>
                    </Link>
                  </p> */}
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-5 col-lg-12">
                        <h3 className="heading heading--h4">
                        {translations['custom.order-info-header-payment'] } 
                        </h3>
                        <p className="zahlung-und-versand-info__content">
                        {translations['custom.order-info-text-payment'] } 
                        </p>
                        <div className="zahlung-und-versand-info__images">
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/Klarna.svg" alt="eps"></img>
                          </div>
                          <br />
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/mc.svg" alt="mc"></img>
                          </div>
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/visa.svg" alt="visa"></img>
                          </div>
                          {/* 
                          <br />
                          <div className="zahlung-und-versand-info__image">
                            <img src="/payment-logos/DCI.svg" alt="dci"></img>
                          </div>
                          */}
                        </div>
                      </div>
                      <div className="col-12 offset-md-1 col-md-5 col-lg-12 offset-lg-0 panel-right"></div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CheckoutSummary;
