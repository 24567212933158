
import {ErrorMessage, Field} from 'formik'

export interface AppProps{
    translations:string[],
    state:any,
    changeHandler?:(e:any)=>void
}

interface SalutationProps extends AppProps {
    salutations:any[]
}



const SalutationField = ({translations,salutations,state,changeHandler}:SalutationProps) => {
        
    function renderSalutationOptions() {
        let renderedSalutations = []
        renderedSalutations.push(<option value="" key="">{translations['custom.select.text']}</option>)

        let salutationList = new Map([
            ["Frau", 1], ['Herr', 2], ['Divers', 3], ['Keine Angabe', 4],
            ["Mrs.", 1], ['Mr.', 2], ['Diverse', 0], ['Not specified', 4]
        ])

        salutations.sort((a, b) => ((salutationList.has(a.displayName) ? salutationList.get(a.displayName) : 999) - (salutationList.has(b.displayName) ? salutationList.get(b.displayName) : 999))).forEach(salutation => {
            if (salutationList.has(salutation.displayName) && salutationList.get(salutation.displayName) === 0) {
            } else {
                renderedSalutations.push(
                    <option id={salutation.id} value={salutation.id} defaultValue={state?.salutation?.id}
                            key={salutation.id}>
                        {salutation.displayName}
                    </option>
                )
            }
        })
        return renderedSalutations
    }


  return( <>
    <label htmlFor="salutationId">{translations['account.personalSalutationLabel']}</label>
                                                      
    <Field
    as="select"
    name="salutationId"
    className="cham-form"
    placeholder="Anrede"
    onChange={(e) => {
        changeHandler(e)
   }}
    >
    {renderSalutationOptions()}
    </Field>
    <ErrorMessage
    name="salutationId"
    component="div"
    className="error"
    />
  </>);
}

export default SalutationField;
